import React from 'react'
import { css } from '@emotion/core'

export default ({ children, theme }) => (
  <p
    css={theme => css(`
      display: block;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 1rem;
      text-transform: uppercase;
    `)}
  >
    {children}
  </p>
)
