import React from 'react'
import { css } from '@emotion/core'

import Layout from '../components/layout'
import H1 from '../components/shared/typography/Heading1'
import P from '../components/shared/typography/Paragraph'

const componentStyles = css`
  section {
    margin-bottom: 3.5rem;
  }
`

const IndexPage = () => (
  <Layout>
    <div css={componentStyles}>
      <section>
        <H1>RIMOWA — THE JOURNEY BEGINS (2018)</H1>
      </section>
      <section>
        <H1>GOLDEN HUM</H1>
      </section>
      <section>
        <P>Paragraph</P>
      </section>
    </div>
  </Layout>
)

export default IndexPage
